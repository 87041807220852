import moment from "moment";
import cn from "classnames";
import { SORTING_TYPE, constants, pipelineColumnLabels, pipelineTooltipText, routes } from "../../../../constants";
import { OriginatingTransaction } from "../../../../types/amr-pipeline/models/OriginatingTransaction";
import { PipelineColumn } from "../../../amrPipeline/types/PipelineColumn";
import { IColumnDefinition } from "../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import StatusLabel from "../../../amrPipeline/StatusLabel";
import { TransactionStatus, transactionStatusTitles } from "../../../../types/amr-pipeline/enums/TransactionStatus";
import { isNil, startCase } from "lodash";
import { BwicStatusLabel, OnHoverTooltip } from "../../../common";
import { dateTimeUtils, formatUtils, moneyUtils } from "../../../../utils";
import IconSVG from "../../../../styles/svg-icons";
import { NewDebutAmrLabel } from "../../../amrPipeline/common/NewDebutAmrLabel";
import { Link } from "react-router-dom";
import { getTransactionDetailsUrl } from "../../../../utils/amr-pipeline.utils";
import { DealSecurity, DealSecurityStatistics } from "../../../../types/security/DealSecurityStatistics";
import { BwicProcessType } from "../../../../types/models/Process";
import { SameDayBwicIcon } from "../../../common/SameDayBwicIcon";
import { Color } from "../../../common/Color";
import { collapseStickRightColumn } from "../../../bidding/common/table/CollapseStickRightColumn";
import { IntexLink } from "../../../common/IntexLink";
import { BloombergLink } from "../../../common/BloombergLink";
import { ValitanaLink } from "../../../common/ValitanaLink";
import { isinCusip as createIsinCusip, isinCusip } from "../../../../types/security/Security";
import { IntexButton } from "../../../amrPipeline/aggregated/IntexButton/IntexButton";
import { Company } from "../../../../types/amr-pipeline/models/Company";
import { DealsBwicDetailsButton } from './DealsBwicDetailsButton';
import { DealsTickerButton } from './DealsTickerButton';
import { DealUpdatesPopover } from '../../../amrPipeline/aggregated/DealUpdatesPopover';
import { MyBwicIcon } from "../../../common/MyBwicIcon";
import { ActionBlocker } from '../../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { BankLink } from "../../../amrPipeline/common/BankLink";
import { SecurityLastTradedDate } from "../../../common/SecurityLastTradedDate";
import { ViewDealDocumentsButton } from "../../../documents/deal-documents-popup/ViewDealDocumentButton";

type TContext = {
    trackDoorClick: () => void;
    trackIntexClick: () => void;
    setTransactionForIntex: (transaction: OriginatingTransaction) => void;
    userCompany: Company;
}

export interface DealSecurityTableItem extends DealSecurity {
    dealReferenceName: string;
    dealLegalName: string;
    isinCusip?: string;
}

export const convertToDealSecurityTableItem = (
    security: DealSecurity,
    transaction: OriginatingTransaction): DealSecurityTableItem => ({
        ...security,
        dealReferenceName: transaction.dealReferenceName,
        dealLegalName: transaction.legalNameOnDeal,
        isinCusip: createIsinCusip(security)
    });

interface OriginatingTransactionTableItem extends OriginatingTransaction {
    arrangerText?: string;
    ncEndYears?: string;
    riEndYears?: string;
}

export const convertToTableItem = (t: OriginatingTransaction): OriginatingTransactionTableItem => ({
    ...t,
    arrangerText: t.arranger?.code ?? t.arranger?.legalName,
    ncEndYears: dateTimeUtils.yrsLeftToDateFrom(t.nonCallPeriodEnd, t.closingDate, false) || undefined,
    riEndYears: dateTimeUtils.yrsLeftToDateFrom(t.reinvestmentPeriodEnd, t.closingDate, false) || undefined
});

// Deal columns ------------------------------------------------------------------------

export const isNewColumn: IColumnDefinition<OriginatingTransaction> = {
    columnKey: PipelineColumn.isNew,
    renderColumnHeaderContent: () => "",
    renderColumnContent: (transaction) => <NewDebutAmrLabel transaction={transaction} ignoreDebut />,
    className: 'data-list-cell-xxs padding-l-0'
};

export const createLastUpdatedColumn = (features?: SubscriptionFeature[]): IColumnDefinition<OriginatingTransaction> => ({
    columnKey: PipelineColumn.lastUpdated,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.lastUpdated],
    renderColumnContent: (transaction, { onLastUpdatedClick, onVersionHistoryClick }) => (
        <ActionBlocker requireAllFeatures features={features}>
            {blocked =>
                <DealUpdatesPopover
                    disabled={blocked}
                    referenceName={transaction.referenceName}
                    dealReferenceName={transaction.dealReferenceName}
                    dealLegalName={transaction.dealLegalName}
                    lastUpdated={transaction.lastUpdated}
                    type={transaction.type}
                    version={transaction.version}
                    status={transaction.status}
                    dealUpdates={transaction.dealUpdates}
                    onOpen={onLastUpdatedClick}
                    onVersionHistoryClick={onVersionHistoryClick}
                />
            }
        </ActionBlocker>
    ),
    className: 'data-list-cell-sm-01',
    sortingField: "lastUpdated",
    sortingType: SORTING_TYPE.date
});

export const dealNameColumn: IColumnDefinition<OriginatingTransaction> = {
    columnKey: PipelineColumn.legalNameOnDeal,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.legalNameOnDeal],
    renderColumnContent: transaction => transaction.legalNameOnDeal,
    className: 'data-list-cell-xl-xxl',
    sortingField: 'legalNameOnDeal',
    sortingType: SORTING_TYPE.string
};

export const dealTickerColumn: IColumnDefinition<OriginatingTransaction> = {
    columnKey: PipelineColumn.dealTicker,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.dealTicker],
    renderColumnContent: transaction => transaction.dealTicker || constants.emptyPlaceholder,
    className: 'data-list-cell-lg',
    sortingField: 'dealTicker',
    sortingType: SORTING_TYPE.string
};

export const transactionStatusColumn: IColumnDefinition<OriginatingTransaction> = {
    columnKey: PipelineColumn.transactionStatus,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.transactionStatus],
    renderColumnContent: transaction => <StatusLabel status={transactionStatusTitles[transaction.status]} />,
    sortingField: 'status',
    sortingType: SORTING_TYPE.string,
};

export const transactionTypeColumn: IColumnDefinition<OriginatingTransaction> = {
    columnKey: PipelineColumn.transactionType,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.transactionType],
    renderColumnContent: transaction => startCase(transaction.type),
    sortingField: 'type',
    sortingType: SORTING_TYPE.string
};

export const transactionCollateralTypeColumn: IColumnDefinition<OriginatingTransaction> = {
    columnKey: PipelineColumn.collateralType,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.collateralType],
    renderColumnContent: transaction => formatUtils.formatCollateralType(transaction.collateralType),
    sortingField: 'collateralType',
    sortingType: SORTING_TYPE.string
};

export const transactionCurrencyColumn: IColumnDefinition<OriginatingTransaction> = {
    columnKey: PipelineColumn.currency,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.currency],
    renderColumnContent: transaction => transaction.currency?.code || constants.emptyPlaceholder,
    sortingField: 'currency',
    sortingType: SORTING_TYPE.transactionCurrency,
};

const arrangerColumn: IColumnDefinition<OriginatingTransactionTableItem> = {
    columnKey: PipelineColumn.arranger,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.arranger],
    renderColumnContent: ({ arranger, arrangerText }) =>
        arranger && arrangerText
            ? (
                <BankLink
                    legalName={arrangerText}
                    referenceName={arranger.referenceName}
                    overlayText={arranger.legalName}
                />
            ) : constants.emptyPlaceholder,
    sortingField: "arrangerText",
    sortingType: SORTING_TYPE.string
};

export const transactionSizeColumn: IColumnDefinition<OriginatingTransaction> = {
    columnKey: PipelineColumn.transactionSize,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.dealSize],
    renderColumnContent: transaction => transaction.dealBalance
        ? moneyUtils.money(transaction.dealBalance)
        : constants.emptyPlaceholder,
    className: 'data-list-cell-md text-right',
    sortingField: "dealBalance",
    sortingType: SORTING_TYPE.number
};

export const pricingDateColumn: IColumnDefinition<OriginatingTransaction> = {
    columnKey: PipelineColumn.pricingDate,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.pricingDate],
    renderColumnContent: transaction =>
        transaction.pricingDate
            ? moment(transaction.pricingDate).format(constants.dateFormat)
            : constants.emptyPlaceholder,
    className: 'data-list-cell-sm',
    sortingField: "pricingDate",
    sortingType: SORTING_TYPE.date
};

export const closingDateColumn: IColumnDefinition<OriginatingTransaction> = {
    columnKey: PipelineColumn.closingDate,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.closingDate],
    renderColumnContent: transaction =>
        transaction.closingDate
            ? moment(transaction.closingDate).format(constants.dateFormat)
            : constants.emptyPlaceholder,
    className: 'data-list-cell-sm',
    sortingField: "closingDate",
    sortingType: SORTING_TYPE.date
};

export const nonCallPeriodEndColumn: IColumnDefinition<OriginatingTransactionTableItem> = {
    columnKey: PipelineColumn.nonCallPeriodEnd,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.nonCallPeriodEnd],
    renderColumnContent: ({ nonCallPeriodEnd }) =>
        nonCallPeriodEnd
            ? moment(nonCallPeriodEnd).format(constants.dateFormatDoubleDay)
            : constants.emptyPlaceholder,
    sortingField: PipelineColumn.nonCallPeriodEnd,
    sortingType: SORTING_TYPE.date,
    className: "data-list-cell-sm",
};

export const nonCallPeriodEndYrsColumn: IColumnDefinition<OriginatingTransactionTableItem> = {
    columnKey: PipelineColumn.nonCallPeriodEndYears,
    renderColumnHeaderContent: () =>
        <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.nonCallPeriodEnd]}>
            {pipelineColumnLabels[PipelineColumn.nonCallPeriodEndYears]}
            <IconSVG name="info" width={16} height={16} />
        </OnHoverTooltip>,
    renderColumnContent: ({ ncEndYears }) => ncEndYears || constants.emptyPlaceholder,
    className: 'data-list-cell-sm text-right',
    sortingField: "ncEndYears",
    sortingType: SORTING_TYPE.number
};

export const outOfNcColumn: IColumnDefinition<OriginatingTransactionTableItem> = {
    columnKey: PipelineColumn.outOfNC,
    renderColumnHeaderContent: () =>
        <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.outOfNC]}>
            {pipelineColumnLabels[PipelineColumn.outOfNC]}
            <IconSVG name="info" width={16} height={16} />
        </OnHoverTooltip>,
    renderColumnContent: ({ outOfNC }) =>
        isNil(outOfNC)
            ? constants.emptyPlaceholder
            : formatUtils.formatBoolean(outOfNC),
    sortingField: PipelineColumn.outOfNC,
    sortingType: SORTING_TYPE.booleanAndNull,
    className: 'data-list-cell-sm text-capitalize',
};

export const reinvestmentPeriodEndColumn: IColumnDefinition<OriginatingTransactionTableItem> = {
    columnKey: PipelineColumn.reinvestmentPeriodEnd,
    renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.reinvestmentPeriodEnd],
    renderColumnContent: ({ reinvestmentPeriodEnd }) =>
        reinvestmentPeriodEnd
            ? moment(reinvestmentPeriodEnd).format(constants.dateFormatDoubleDay)
            : constants.emptyPlaceholder,
    sortingField: PipelineColumn.reinvestmentPeriodEnd,
    sortingType: SORTING_TYPE.date,
    className: "data-list-cell-sm",
};

export const reinvestmentPeriodEndYrsColumn: IColumnDefinition<OriginatingTransactionTableItem> = {
    columnKey: PipelineColumn.reinvestmentPeriodEndYears,
    renderColumnHeaderContent: () =>
        <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.reinvestmentPeriodEnd]}>
            {pipelineColumnLabels[PipelineColumn.reinvestmentPeriodEndYears]}
            <IconSVG name="info" width={16} height={16} />
        </OnHoverTooltip>,
    renderColumnContent: ({ riEndYears }) => riEndYears || constants.emptyPlaceholder,
    sortingField: "riEndYears",
    sortingType: SORTING_TYPE.number,
    className: "data-list-cell-sm text-right",
};

export const outOfRiColumn: IColumnDefinition<OriginatingTransactionTableItem> = {
    columnKey: PipelineColumn.outOfRI,
    renderColumnHeaderContent: () =>
        <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.outOfRI]}>
            {pipelineColumnLabels[PipelineColumn.outOfRI]}
            <IconSVG name="info" width={16} height={16} />
        </OnHoverTooltip>,
    renderColumnContent: ({ outOfRI }) =>
        isNil(outOfRI)
            ? constants.emptyPlaceholder
            : formatUtils.formatBoolean(outOfRI),
    sortingField: PipelineColumn.outOfRI,
    sortingType: SORTING_TYPE.booleanAndNull,
    className: 'data-list-cell-sm text-capitalize',
};

const downloadDocumentsColumn: IColumnDefinition<OriginatingTransaction, { securities: DealSecurityStatistics[], hasDocuments?: boolean }> = {
    columnKey: 'download-documents',
    renderColumnHeaderContent: () => 'Docs',
    renderColumnContent: (transaction, context) => {
        const security =
            transaction.hasDocuments && transaction.dealTicker
                ? context.securities.find(
                        (s) => s.ticker === transaction.dealTicker
                    )
                : null;

        const firstSecurity = security?.classes[0];
        const isinCusipValue = firstSecurity
            ? isinCusip(firstSecurity)
            : null;

        if (!firstSecurity || !isinCusipValue) {
            return constants.emptyPlaceholder;
        }

        return (
            <div onClick={(e) => e.stopPropagation()}>
                <ViewDealDocumentsButton
                    className="text-regular"
                    security={{ ...firstSecurity, isinCusip: isinCusipValue }}
                    requiredFeature={SubscriptionFeature.BwicMonitorHistoricalData}
                />
            </div>
        );
    },
    className: 'data-list-cell-lg data-list-cell-download-deal-documents',
    sortingField: "hasDocuments",
    sortingType: SORTING_TYPE.boolean
};

export const dealIntexColumn: IColumnDefinition<OriginatingTransaction, TContext> = {
    columnKey: 'dealIntex',
    renderColumnHeaderContent: () => '',
    renderColumnContent: (transaction, context) => (
        <IntexButton
            placeholderIfEmpty
            secondaryPlaceholderColor
            userCompany={context.userCompany}
            transaction={transaction}
            onClick={context.setTransactionForIntex}
            onClickLink={context.trackIntexClick}
        />
    ),
    className: 'data-list-cell-xxs text-right padding-r-0',
    stickRight: true,
};

export const createRelatedTransactionsColumn = (features?: SubscriptionFeature[]): IColumnDefinition<OriginatingTransaction> => ({
    columnKey: 'relatedTransactions',
    renderColumnHeaderContent: () => '',
    renderColumnContent: transaction => {
        if (transaction.numberOfDealPublishedTransactions <= 1) {
            return (<span className="action-empty-placeholder">{constants.emptyPlaceholder}</span>);
        }

        return (
            <ActionBlocker requireAllFeatures features={features}>
                {blocked => blocked ? (
                    <span className="btn-link btn-link-no-label disabled">
                        <IconSVG name="related-transaction" width={16} height={16} />
                    </span>
                ) : (
                    <OnHoverTooltip overlay="Related Transactions" placement="right">
                        <Link
                            target="_blank"
                            to={`${routes.AMRPipeline}/?dealsReferenceNames=${transaction.dealReferenceName}&dealsLegalNames=${transaction.legalNameOnDeal}`}
                            className="btn-link btn-link-no-label"
                            onClick={e => e.stopPropagation()}
                        >
                            <IconSVG name="related-transaction" width={16} height={16} />
                        </Link>
                    </OnHoverTooltip>
                )}
            </ActionBlocker>
        );
    },
    headerClassName: 'data-list-cell-auto text-right',
    bodyClassName: 'data-list-cell-xxs text-right',
    stickRight: true,
});

export const createGoToColumn = (features?: SubscriptionFeature[]): IColumnDefinition<OriginatingTransaction, TContext> => ({
    columnKey: "goTo",
    renderColumnHeaderContent: () => "Actions",
    renderColumnContent: (transaction, context) =>
        <ActionBlocker
            features={features}
            requireAllFeatures
            overrideRequiredFeatures={transaction.status === TransactionStatus.Active}
        >
            {blocked => blocked ? (
                <button className="btn-link disabled" disabled>
                    <IconSVG name="go-to-details" width={16} height={16} />
                </button>
            ) : (
                <OnHoverTooltip overlay="See on Issuance Monitor">
                    <Link
                        target="_blank"
                        to={getTransactionDetailsUrl(transaction)}
                        className={cn('btn-link', { disabled: blocked })}
                        onClick={e => {
                            e.stopPropagation();
                            context.trackDoorClick && context.trackDoorClick();
                        }}
                    >
                        <IconSVG name="go-to-details" width={16} height={16} />
                    </Link>
                </OnHoverTooltip>
            )}
        </ActionBlocker>,
    headerClassName: 'data-list-cell-xxs-02 text-right padding-r-0',
    bodyClassName: 'data-list-cell-xxs text-right padding-r-0',
    stickRight: true
});

export const dealOptionsColumn: IColumnDefinition<OriginatingTransaction> = {
    columnKey: 'deal-options',
    renderColumnHeaderContent: () => '',
    renderColumnContent: () => '',
    headerClassName: 'data-list-cell-xxs',
    bodyClassName: 'data-list-cell-xxxs',
    stickRight: true
};

export const dealColumns = [
    isNewColumn,
    createLastUpdatedColumn([SubscriptionFeature.ManagerProfileDeals, SubscriptionFeature.IssuanceMonitorHistoricalData]),
    dealNameColumn,
    dealTickerColumn,
    transactionStatusColumn,
    transactionTypeColumn,
    transactionCollateralTypeColumn,
    transactionCurrencyColumn,
    arrangerColumn,
    transactionSizeColumn,
    pricingDateColumn,
    closingDateColumn,
    nonCallPeriodEndColumn,
    nonCallPeriodEndYrsColumn,
    outOfNcColumn,
    reinvestmentPeriodEndColumn,
    reinvestmentPeriodEndYrsColumn,
    outOfRiColumn,
    downloadDocumentsColumn,
    collapseStickRightColumn,
    dealOptionsColumn,
    dealIntexColumn,
    createGoToColumn([SubscriptionFeature.ManagerProfileDeals, SubscriptionFeature.IssuanceMonitorHistoricalData]),
    createRelatedTransactionsColumn([SubscriptionFeature.ManagerProfileDeals, SubscriptionFeature.IssuanceMonitorMarketingHistory]),
].map((c) => new ColumnBuilder(c));


// Security columns ----------------------------------------------------------------------

const ticker: IColumnDefinition<DealSecurityTableItem, TContext> = {
    columnKey: 'ticker',
    renderColumnHeaderContent: () => 'Ticker',
    renderColumnContent: security => <DealsTickerButton security={security} />,
    className: 'data-list-cell-lg-02',
    sortingField: 'ticker',
    sortingType: SORTING_TYPE.string
};
const myBwic: IColumnDefinition<DealSecurity> = {
    columnKey: 'is-my-bwic',
    renderColumnHeaderContent: () => '',
    renderColumnContent: security => security.currentBwic ? (
        <MyBwicIcon bwic={security.currentBwic} />
    ) : null,
    className: 'data-list-cell-xxs',
};
const currentBWIC: IColumnDefinition<DealSecurity> = {
    columnKey: 'current-bwic',
    renderColumnHeaderContent: () => 'Current BWIC',
    renderColumnContent: security => security.currentBwic ?
        <BwicStatusLabel
            status={security.currentBwic.status}
            liveBidding={security.currentBwic.process?.type === BwicProcessType.Live}
            isParsed={security.currentBwic.isParsed}
            directBidding={security.currentBwic.isClearingBankParticipant}
            isAllToAll={security.currentBwic.isAllToAll}
        />
        : constants.emptyPlaceholder,
    className: 'data-list-cell-lg'
};
const sameDay: IColumnDefinition<DealSecurity> = {
    columnKey: 'same-day',
    renderColumnHeaderContent: () => '',
    renderColumnContent: security => security.currentBwic?.isSameDayBwic && <SameDayBwicIcon />,
    className: 'data-list-cell-xxs',
};
const viewCurrentBwic: IColumnDefinition<DealSecurity> = {
    columnKey: 'view-current-bwic',
    renderColumnHeaderContent: () => '',
    renderColumnContent: (security) => security.currentBwic
        ? <DealsBwicDetailsButton security={security} />
        : constants.emptyPlaceholder,
    className: 'data-list-cell-lg'
};
const lastTradedToMe: IColumnDefinition<DealSecurity> = {
    columnKey: 'last-trader-my',
    renderColumnHeaderContent: () => '',
    renderColumnContent: security => (
        <SecurityLastTradedDate inventoryLastTraded={security.inventoryLastTradeDate} bwicLastTradedDate={security.lastTraded?.bidsDueUtc}>
            {(isInventoryDate) => (security.lastTraded && !isInventoryDate) ? <MyBwicIcon bwic={security.lastTraded} /> : null}
        </SecurityLastTradedDate>
    ),
    className: 'data-list-cell-xxs'
};
const lastTraded: IColumnDefinition<DealSecurity> = {
    columnKey: 'last-traded',
    renderColumnHeaderContent: () => 'Last Traded',
    renderColumnContent: security =>
    (
        <SecurityLastTradedDate inventoryLastTraded={security.inventoryLastTradeDate} bwicLastTradedDate={security.lastTraded?.bidsDueUtc}>
            {(isInventoryDate, lastTradeDate) => lastTradeDate ? moment(lastTradeDate).format(constants.dateFormat) : constants.emptyPlaceholder}
        </SecurityLastTradedDate>
    ),
    className: 'data-list-cell-sm',
    sortingField: 'lastTraded.bidsDueUtc',
    sortingType: SORTING_TYPE.date

};
const lastTradedColor: IColumnDefinition<DealSecurity> = {
    columnKey: 'last-traded-color',
    renderColumnHeaderContent: () => '',
    renderColumnContent: security => (
        <SecurityLastTradedDate inventoryLastTraded={security.inventoryLastTradeDate} bwicLastTradedDate={security.lastTraded?.bidsDueUtc}>
            {
                (isInventoryDate) =>
                    security.lastTraded?.color && !isInventoryDate
                        ? <Color color={security.lastTraded?.color} />
                        : null
            }
        </SecurityLastTradedDate>),
    className: 'data-list-cell-md'
};
const securityClosingDate: IColumnDefinition<DealSecurity> = {
    columnKey: 'closing-date',
    renderColumnHeaderContent: () => 'Closing',
    renderColumnContent: s => s.closingDate
        ? moment(s.closingDate).format(constants.dateFormat)
        : constants.emptyPlaceholder,
    className: 'data-list-cell-sm-flexible',
    sortingField: 'closingDate',
    sortingType: SORTING_TYPE.date
};
const collaspsePlaceholder: IColumnDefinition<DealSecurity> = {
    columnKey: 'collapse-placeholder',
    renderColumnHeaderContent: () => '',
    renderColumnContent: () => '',
    className: 'data-list-cell-xxs data-list-cell-icon-collapsed'
};
const intex: IColumnDefinition<DealSecurityTableItem, TContext> = {
    columnKey: 'intex',
    renderColumnHeaderContent: () => '',
    renderColumnContent: (security, context) => {
        if (!security.isinCusip) {
            return constants.emptyPlaceholder
        }

        return (
            <IntexLink
                positionId={security.isinCusip}
                onClick={context.trackIntexClick}
            />
        );
    },

    className: 'data-list-cell-xxs text-right padding-r-0 flex-item-right'
};
const bloomberg: IColumnDefinition<DealSecurityTableItem> = {
    columnKey: 'bloomberg',
    renderColumnHeaderContent: () => '',
    renderColumnContent: security => (
        <BloombergLink identifier={security.isinCusip} />
    ),
    headerClassName: 'data-list-cell-xxxs text-right padding-r-0',
    className: 'data-list-cell-xxs text-right padding-r-0'
};
const valitana: IColumnDefinition<DealSecurityTableItem> = {
    columnKey: 'valitana',
    renderColumnHeaderContent: () => '',
    renderColumnContent: security => (
        <ValitanaLink identifier={security.isinCusip} />
    ),
    className: 'data-list-cell-xxs text-right'
}

export const securityColumns = {
    left: [],
    middle: [
        ticker, myBwic, currentBWIC, sameDay, viewCurrentBwic,
        lastTradedToMe, lastTraded, lastTradedColor, securityClosingDate].map(c => new ColumnBuilder(c)),
    right: [collaspsePlaceholder, intex, bloomberg, valitana].map(c => new ColumnBuilder(c))
};
